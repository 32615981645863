import React, { useState, useEffect, Suspense, lazy } from "react";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import { ApiUrl } from "./API/ApiUrl";
import Header from "./layout/partials/Header";
import Footer from "./layout/partials/Footer";
import Scrollbar from "./Components/Scrollbar";
import Counter from "./Components/Counter";
import HomeBar from "./Components/HomeBar";
import MassTime from "./Components/MassTime";
import Buttons from "./Components/Buttons";
import Striptext from "./Components/Striptext";

const Slider = lazy(() => import("./Components/Slider"));
const News = lazy(() => import("./Components/News"));
const AllPriest = lazy(() => import("./Components/AllPriest"));
const Youtube = lazy(() => import("./Components/Youtube"));
const ParishEvents = lazy(() => import("./Components/ParishEvents"));
const ChurchHistory = lazy(() => import("./Components/ChurchHistory"));
const ParishVideos = lazy(() => import("./Components/ParishVideos"));
const ParishAnnouncement = lazy(() => import("./Components/ParishAnnouncement"));

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [homedata, setHomedata] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cachedData = JSON.parse(localStorage.getItem("HomeData"));
        const cachedTimestamp = localStorage.getItem("HomeDataTimestamp");
        const response = await axios.get(`${ApiUrl}/get/homepagee/sections`);
        const apiData = response?.data?.data;
        const apiTimestamp = new Date().toISOString();
        if (
          cachedData &&
          cachedTimestamp &&
          new Date(apiTimestamp) <= new Date(cachedTimestamp)
        ) {
          setHomedata(cachedData);
        } else {
          localStorage.setItem("HomeData", JSON.stringify(apiData));
          localStorage.setItem("HomeDataTimestamp", apiTimestamp);
          setHomedata(apiData);
        }
      } catch (error) {
        let collection = localStorage.getItem("HomeData");
        setHomedata(collection ? JSON.parse(collection) : null);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (homedata) {
      const totalImages = document.querySelectorAll("img").length;
      if (totalImages === 0) {
        setLoading(false);
      }
    }
  }, [homedata]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ThreeDots
          visible={true}
          height="50"
          width="50"
          color="#900404"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }

  return (
    <>
      <Header menudata={homedata?.headermenudata} />
      <Suspense fallback={<div>Loading...</div>}>
        <Slider sliderdata={homedata?.SlidesData} />
        <Scrollbar />
        <Counter />
        <HomeBar />
        <ChurchHistory />
        <ParishVideos />
        <News allnewsdata={homedata?.newsdata} />
        <AllPriest allpriestdata={homedata?.teammembers} />
        <MassTime />
        <Buttons />
        <ParishAnnouncement announcementdata={homedata?.announcementdata} />
        <ParishEvents events={homedata?.parishnewsdata} />
        <br />
        <Youtube events={homedata?.parishnewsdata} />
        <br />
        <br />
        <Striptext />
        <Footer footerdata={homedata?.footercontactdata} />
      </Suspense>
    </>
  );
};

export default Home;